<template>
  <div class="container">
    <a class="btn__back" @click="$router.go(-1)">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z"
          fill="#DB353A"
        />
      </svg>
      <span>Back</span>
    </a>
    <h2 class="leading">Choose your sign up preference</h2>
    <div class="btn__groups">
      <!-- <button class="btn btn__black" @click="$router.push('/signup/question')"> -->
      <button class="btn btn__black" @click="$router.push('/signup/existing-user')">
        Sign up using OneBank
      </button>
      <button class="btn btn__red" @click="$router.push('/signup/with-one-pay')">
        Sign up using OnePay
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
